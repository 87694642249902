import React, { useState, useEffect } from 'react'
import {getHoverEffectValue, isItUndefined, isLinkAbsolute, isMobile, renderTextColor} from "../../util/DataUtil";
import { useRouter } from "next/router";
import SmallLogoWall from "./small-logo-wall";
import LargeLogoWall from "./large-logo-wall";
import Image from "next/image";

const onMouseEnter = (item, setHover) => {
    setHover(item)
}

const onMouseLeave = (item, setHover) => {
    setHover(false)
}

const Partnership = (data) => {
    if(!data){
        return;
    }

    const router = useRouter()
    const [text, setText] = useState('');
    const [hover, setHover] = useState(false);

    const logos = data?.children?.field_partnership_logo?.partnership_logos_paragraph_type;
    let alignTitle = isItUndefined(data.children?.field_align_main_title);

    if(!logos){
        return <></>;
    }

    useEffect(() => {
        setText(isItUndefined(data?.children?.field_partnership_text))
    }, [])

    const getAlignment = () => {
        if(alignTitle !== undefined){
            return alignTitle === '1' ? "center" : '';
        }
    }

    const getCursor = (item) => {
        return isItUndefined(item?.field_partnership_url) ? ' pointer' : '';
    }

    const getHoverEffect = (hoverEffect) => {
        if(hoverEffect === undefined){
            return ' color'
        }

        return " " + getHoverEffectValue(hoverEffect);
    }

    const renderImage = (image) => {
        return image?.field_logo_image[0] ?
            <div className={'logo-wrapper'}>
                <Image alt="partner-logo" src={image?.field_logo_image[0]} layout="fill" />
            </div> :
            isItUndefined(image?.field_part, 'uri') ?
                <img src={isItUndefined(image?.field_part, 'uri')} alt={'partner-logo'} /> :
                ''
    }

    const renderLogos = () => {
        return logos.map((item, key) => {
            return (
                <div className={'div-partnership-link logo' + getCursor(item) + getHoverEffect(isItUndefined(data?.children?.field_choose_your_hover_effect))} key={'partnership-key-' + key}
                     style={{zIndex: hover.id === item.id ? 1 : 0}}
                     onClick={() => {
                        if (!item?.field_partnership_url[0]?.value){
                            return
                        }

                        if (isLinkAbsolute(item?.field_partnership_url[0]?.value)){
                            window.open(item?.field_partnership_url[0]?.value, '_blank');
                        }else {
                            if (item?.field_partnership_url[0]?.value.includes('/')){
                                router.push(item?.field_partnership_url[0]?.value)
                            }else{
                                router.push('/' + item?.field_partnership_url[0]?.value)
                            }
                        }
                    }}
                    onMouseEnter={() => onMouseEnter(item, setHover)}
                    onMouseLeave={() => onMouseLeave(item, setHover)}
                >
                    {renderImage(item)}
                    {/*<img src={item.field_part ? item.field_part[0]?.uri : ""} alt={item.field_part ? item.field_part[0]?.alt : "partner-logo"} />*/}
                    {/*<img src={'https://dlr-backend.getdeveloper.net/sites/default/files/2022-10/image%2013_0.png'} alt={item.field_part ? item.field_part[0]?.alt : "partner-logo"} />*/}
                    {/*<img src={'https://dlr-backend.getdeveloper.net/sites/default/files/2023-01/googlecolor.png'} alt={item.field_part ? item.field_part[0]?.alt : "partner-logo"} />*/}
                </div>
            );
        });
    }

    const returnBackground = () => {
        if (!data?.children?.field_partnership_background){
            return ''
        }

        if (data?.children?.field_partnership_background[0]?.value === '1'){
            return 'gray'
        }else{
            return 'white'
        }
    }

    const getWidth = () => {
        let viewportWidth;

        if(typeof window !== 'undefined'){
            viewportWidth = window.innerWidth;
        }

        return isMobile(viewportWidth);
    }

    return(
        <div id={'partnership'} >
            {
                Number(isItUndefined(data?.children?.field_choose_logo_wall_version)) === 0
                ?
                <SmallLogoWall data={data} text={text} renderLogos={renderLogos} returnBackground={returnBackground} getWidth={getWidth} getAlignment={getAlignment} />
                :
                <LargeLogoWall data={data} text={text} renderLogos={renderLogos} returnBackground={returnBackground} getWidth={getWidth} getAlignment={getAlignment} />
            }
        </div>
    );
}

export default Partnership;
